import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "./Controls.css";

const Controls = (props) => {
  const [images, setImages] = useState([]);
  // const [imageURLs, setImageURLs] = useState([]);

  const uploadFileRef = useRef(null);

  useEffect(() => {
    if (images.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    //setImageURLs(newImageURLs);
    props.link(newImageURLs[0]);
  }, [images]);

  const onImageChange = (event) => {
    // const image = event.target.files;
    // const url = URL.createObjectURL(image);
    // console.log(url);
    setImages([...event.target.files]);
  };
  // console.log(imageURLs);
  const triggerFileUpload = () => {
    uploadFileRef.current.click();
  };

  const colsHandler = (event) => {
    props.cols(event.target.value);
  };
  const rowsHandler = (event) => {
    props.rows(event.target.value);
  };

  return (
    <div className="image-grids-controls">
      <TextField
        id="outlined-input"
        label="Coloane"
        variant="outlined"
        size="small"
        type="number"
        InputProps={{ inputProps: { min: 1, max: 30 } }}
        onChange={colsHandler}
        fullWidth
      />
      <TextField
        id="outlined-input"
        label="Rânduri"
        variant="outlined"
        size="small"
        type="number"
        InputProps={{ inputProps: { min: 1, max: 30 } }}
        onChange={rowsHandler}
        fullWidth
      />
      <Button variant="contained" onClick={triggerFileUpload} fullWidth>
        Alege poză
      </Button>
      <input
        id="uploadInput"
        type="file"
        multiple
        accept="image/*"
        ref={uploadFileRef}
        onChange={onImageChange}
      />
    </div>
  );
};

export default Controls;
