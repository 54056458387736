import React from "react";
import "./Copyright.css";

const Copyright = () => {
  return (
    <div id="copyright">
      <strong>&copy;2022 B87TM</strong>
    </div>
  );
};

export default Copyright;
