import React, { useState } from "react";
import "./App.css";
import Controls from "./components/Controls";
import Image from "./components/Image";
import Copyright from "./components/Copyright";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

function App() {
  const [imgURL, setImgURL] = useState("");
  const [cols, setCols] = useState(0);
  const [rows, setRows] = useState(0);

  const imageURLsHandler = (item) => {
    setImgURL(item);
  };

  const onColsInput = (input) => {
    setCols(input);
  };
  const onRowsInput = (input) => {
    setRows(input);
  };

  // console.log("Logged from App.js");
  // console.log(imgURL);
  return (
    <div className="image-grids-wrapper">
      <Controls link={imageURLsHandler} cols={onColsInput} rows={onRowsInput} />
      {imgURL !== "" && <Image image={imgURL} rows={rows} cols={cols} />}
      {imgURL === "" && (
        <div id="no-img-loaded">
          <h1>Încarcă o poză întâi</h1>
          <SentimentSatisfiedAltIcon
            color="primary"
            sx={{ fontSize: 130 }}
          ></SentimentSatisfiedAltIcon>
        </div>
      )}
      <Copyright />
    </div>
  );
}

export default App;
