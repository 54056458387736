import React from "react";
import "./Image.css";

const Image = (props) => {
  const cols = props.cols;
  const rows = props.rows;
  const col = 100 / cols;
  const row = 100 / rows;
  const totalGrids = cols * rows;

  let grids = [];
  for (let i = 1; i <= totalGrids; i++) {
    grids.push({ id: `grid_${i}` });
  }

  return (
    <div className="image-grids-wrapper">
      <div
        className="image-grids-image"
        style={{
          backgroundImage: `url(${props.image})`,
        }}
      >
        <img src={props.image} alt="Una bucată imagine" />
        <div className="grids-wrapper">
          {grids.map((grid) => {
            return (
              <div
                className="grid"
                id={grid.id}
                key={grid.id}
                style={{ width: `${col}%`, height: `${row}%` }}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Image;
